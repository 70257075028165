module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ChristianLobaugh","short_name":"ChristianLobaugh","start_url":"/","background_color":"#ffffff","theme_color":"#2E52A3","display":"standalone","icon":"src/images/favicon.png","theme_color_in_head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://christianlobaugh.com","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
